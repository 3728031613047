(function () {
  'use strict';

  angular
    .module('neo.home.championships')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.championships', {
        url: '/championships',
        templateUrl: 'home/championships/championships.tpl.html',
        controller: 'ChampionshipsCtrl',
        controllerAs: 'vm',
        resolve: {
          championships: function (Championships) {
            return Championships.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          programmes: function (Programmes) {
            return Programmes.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          phases: function (Phases) {
            return Phases.query().$promise;
          }
        }
      });
  }
}());
